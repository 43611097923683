import React from "react";
import { Modal, Button } from "react-bootstrap";
import Animate from "./animation";

function ReactAlert({ message, onRequestClose, open }) {
  return (
    <Modal
      show={open}
      onHide={onRequestClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Body className="p-3 py-4 text-center">{message}</Modal.Body>
      <Modal.Footer className="border-0">
        <Button
          variant="secondary"
          className="mr-auto ml-auto"
          onClick={onRequestClose}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ReactAlert;
