import React, { Fragment, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Form } from 'react-bootstrap';

function MainForm({ data, ...props }) {
  const [selected, setSelected] = useState({});
  const [error, setError] = useState(false);

  useEffect(() => {
    const tempSelected = {};

    for (let { path } of data) {
      tempSelected[path] = false;
      console.log('item', path);
    }
    setSelected(tempSelected);
  }, []);

  const handleRedirect = () => {
    if (!selected) {
      window.scrollTo(0, 0);
      return setError(true);
    }
    const urlParams = new URLSearchParams(window.location.search);
    const clickId = urlParams.get('clickId');
    const parent = urlParams.get('parent');
    console.log('clickId', urlParams, clickId, window.location.search);
    if (selected['A']) {
      return props.history.push(`8366?clickId=${clickId}&parent=${parent}`);
    } else if (
      selected['B'] ||
      selected['C'] ||
      selected['D'] ||
      selected['E'] ||
      selected['G']
    ) {
      return props.history.push(`8365?clickId=${clickId}&parent=${parent}`);
    } else if (selected['F'] || selected['H'] || selected['I']) {
      return props.history.push(`8364?clickId=${clickId}&parent=${parent}`);
    }
  };

  const handleSelect = (path) => {
    console.log('path', path, selected);
    setSelected((allSelected) => ({ ...allSelected, [path]: !selected[path] }));
    setError(false);
  };

  return (
    <Fragment>
      <div className="p-1 p-md-4">
        <h3 className="font-weight-bold text-muted my-2">{props.title}</h3>
        {error && (
          <span className="text-danger font-weight-bold d-block mb-2">
            <i className="fa fa-arrow-down mr-2" aria-hidden="true" />
            Please choose a category
          </span>
        )}
        <div className="my-2 mt-3">
          {data.map(({ name, path }, index) => (
            <div key={index}>
              <Form.Label
                className={
                  path === selected[path]
                    ? 'show-border text-gray d-flex align-items-center radiobutton scrim-card list-item p-3'
                    : 'text-gray d-flex align-items-center radiobutton scrim-card list-item p-3'
                }>
                <input
                  type={'checkbox'}
                  name={name}
                  checked={selected[path]}
                  onChange={() => handleSelect(path)}
                  className="form-radio"
                />
                <span className="ml-2 text-label">{name}</span>
              </Form.Label>
            </div>
          ))}
        </div>
        <div>
          <button
            className="btn btn-warning btn-lg mt-5 next-btn"
            onClick={() => handleRedirect()}>
            NEXT{' '}
            <i
              className="ml-1 fa fa-play text-dark font-weight-bolder"
              aria-hidden="true"
            />
          </button>
        </div>
      </div>
    </Fragment>
  );
}

export default withRouter(MainForm);
