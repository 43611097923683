import React from "react";
import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import PhoneInput from "react-phone-number-input/input";

function Input({
  label,
  name,
  type,
  as,
  isRequired = true,
  inputRef = {},
  isPhoneNumber = false,
}) {
  const { register, errors, ...methods } = useFormContext();

  return (
    <Form.Group>
      {errors[name] && (
        <span className="text-danger font-weight-bold d-block mb-2">
          <i className="fa fa-arrow-down mr-2" aria-hidden="true" />
          {errors[name].message}
        </span>
      )}
      <Form.Label>
        {label}
        {isRequired && (
          <span className="text-danger font-weight-bolder ml-1 is-required">
            *
          </span>
        )}
      </Form.Label>
      {isPhoneNumber ? (
        <PhoneInput
          country="US"
          international
          withCountryCallingCode
          name={name}
          className={`${
            errors[name] && "is-invalid"
          } list-item scrim-card form-control`}
          value={methods.getValues(name)}
          onChange={(text) => {
            methods.setValue(name, text);
            methods.trigger(name);
          }}
          ref={
            isRequired &&
            register(
              { name },
              { required: "Please provide an answer", ...inputRef }
            )
          }
        />
      ) : (
        <Form.Control
          type={type || "text"}
          name={name}
          as={as}
          ref={
            isRequired &&
            register({ required: "Please provide an answer", ...inputRef })
          }
          className={`list-item scrim-card ${errors[name] && "is-invalid"}`}
        />
      )}
    </Form.Group>
  );
}

const CustomInput = React.forwardRef(({ className, name, ...props }) => {
  const methods = useFormContext();
  console.log("props", props, methods.getValues(), methods.getValues("phone"));
  return (
    <PhoneInput
      className={className}
      placeholder="Enter phone number"
      country="US"
      name={name}
      withCountryCallingCode
      value={methods.getValues("phone")}
      onChange={(text) => {
        methods.setValue("phone", text);
      }}
      ref={methods.register(
        { name },
        {
          required: "Please provide an answer",
          max: 10,
          pattern: {
            message: "Please enter a valid Zip Code",
          },
        }
      )}
    />
  );
});

export default Input;
