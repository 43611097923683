import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import useStep from 'hooks/useStep';

import { HomeAdvisorBadge } from 'components';
import GeneralForm from './generalForm';
import PersonalForm from '../personal';

export default function KitchenRemodelingForm() {
  const [task, setTask] = useState();
  const { activeStep, onNext } = useStep(0);
  const components = [
    <GeneralForm
      onNext={(task) => {
        setTask(task);
        onNext();
      }}
    />,
    <PersonalForm task={task} />,
  ];
  return (
    <Container fluid className="p-0 px-md-3 py-md-4">
      {components[activeStep]}
      <HomeAdvisorBadge />
    </Container>
  );
}
