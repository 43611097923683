import React from "react";
import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

const RadioButton = ({ name, title, fields, isRequired, className }) => {
  const { register, errors } = useFormContext();
  const [state, setState] = React.useState({ selectedOption: null });

  const _handleChange = (e, answerId) => {
    fields.map(({ _attributes: { id } }, index) => {
      if (id === answerId) return (fields[index].isSelected = true);
      else return (fields[index].isSelected = false);
    });
    setState({ selectedOption: answerId });
  };

  return (
    <Form.Group>
      {errors[name] && (
        <span className="text-danger font-weight-bold d-block mb-2">
          <i className="fa fa-arrow-down mr-2" aria-hidden="true" />
          {errors[name].message}
        </span>
      )}
      <Form.Label>
        {title}
        {isRequired && (
          <span className="text-danger font-weight-bolder ml-1 is-required">
            *
          </span>
        )}
      </Form.Label>
      <Form.Row
        className={`p-0 m-0  ${
          "inline" ? "d-flex align-items-center" : "d-block"
        }`}
      >
        {fields.map(({ _text, _attributes: { id } }, index) => (
          <div className={className} key={index}>
            <Form.Label
              className={
                state.selectedOption === id
                  ? "text-gray d-flex align-items-center radiobutton scrim-card list-item p-3 show-border"
                  : "text-gray d-flex align-items-center radiobutton scrim-card list-item p-3"
              }
            >
              <input
                type={"radio"}
                name={name}
                checked={state.selectedOption === id}
                onChange={(event) => _handleChange(event, id)}
                className="form-radio"
                ref={
                  isRequired
                    ? register({
                        required: "This field is required",
                      })
                    : null
                }
              />
              <span className="ml-2 text-label">{_text}</span>
            </Form.Label>
          </div>
        ))}
      </Form.Row>
    </Form.Group>
  );
};

export default RadioButton;
