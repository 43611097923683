import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Form, Alert } from 'react-bootstrap';
import { Checkbox, RadioButton, Select, Input } from 'components';
import { useForm, FormProvider } from 'react-hook-form';

function MyForm({ onNext, ...props }) {
  let { task: data } = require(`../../json${props.location.pathname}.json`);
  const methods = useForm();
  const onSubmit = (values) => onNext(data);

  const goBack = () => props.history.goBack();
  return (
    <FormProvider {...methods}>
      {Object.keys(methods.errors).length > 0 && (
        <Alert className="bg-danger text-light mt-3 small">
          There were form errors, Please correct them and try again
        </Alert>
      )}
      <Form className="mt-3" onSubmit={methods.handleSubmit(onSubmit)}>
        {data.task.question.map(
          (
            { _attributes: { description, name }, _type, _isRequired, answer },
            index
          ) => {
            if (_type === 'CHECK_BOX' || _type === 'RADIO_GROUP_CHECK_BOX')
              return (
                <div key={index}>
                  <Checkbox
                    name={name}
                    title={description}
                    isRequired={_isRequired}
                    fields={answer}
                    className="col-12 col-md-6"
                  />
                </div>
              );
            if (
              _type === 'RADIO_BUTTON' ||
              _type === 'RADIO_SINGLE_LINE' ||
              _type === 'RADIO_SINGLE_LINE_LEFT' ||
              _type === 'RADIO_ONEPER_LINE'
            )
              return (
                <div key={index}>
                  <RadioButton
                    name={name}
                    title={description}
                    isRequired={_isRequired}
                    fields={answer}
                    className="col-12 col-md-6"
                  />
                </div>
              );
            else if (
              _type === 'DROP_LIST' ||
              _type === 'DROP_LIST_RIGHT_ADJUSTED' ||
              _type === 'DROP_LIST_NO_SELECT_ONE'
            ) {
              return (
                <Select
                  name={name}
                  title={description}
                  isRequired={_isRequired}
                  fields={answer}
                  className="col-12 col-md-6"
                />
              );
            } else if (_type === 'TEXT_BOX_MULTIPLE' || _type === 'TEXT_AREA') {
              return (
                <Input
                  name={name}
                  title={description}
                  label={description}
                  as={'textarea'}
                  isRequired={_isRequired}
                  fields={answer}
                  className="col-12 col-md-6"
                />
              );
            } else if (_type === 'TEXT_BOX_ZIPCODE') {
              return (
                <Input
                  name={'zip'}
                  label={'Zip code'}
                  inputRef={{
                    required: true,
                    pattern: {
                      value: /^[0-9]{5}(?:-[0-9]{4})?$/,
                      message: 'Please enter a valid Zip Code',
                    },
                  }}
                />
              );
            } else {
              return (
                <Input
                  name={name}
                  title={description}
                  label={description}
                  type={
                    _type === 'CALENDAR'
                      ? 'datetime-local'
                      : _type === 'DATE'
                      ? 'date'
                      : _type === 'TEXT_BOX'
                      ? 'text'
                      : 'text'
                  }
                  isRequired={_isRequired}
                  fields={answer}
                  className="col-12 col-md-6"
                />
              );
            }
          }
        )}
        {/* <button
          className="btn btn-secondary btn-lg ml-2 mr-2 next-btn"
          onClick={goBack}>
          <i
            className="fa fa-chevron-left text-dark font-weight-bolder"
            aria-hidden="true"
          />
        </button> */}
        <Button
          variant="warning"
          size="lg"
          type="submit"
          className="font-weight-bolder">
          Click Here to Continue{' '}
        </Button>
      </Form>
    </FormProvider>
  );
}

export default withRouter(MyForm);
