import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { services, mainRoutes } from "json";

function Home() {
  return (
    <Fragment>
      {services.map((taskOid, index) => (
        <div className="d-block m-2" key={index}>
          <Link to={`/${taskOid}`}>{taskOid}</Link>
        </div>
      ))}
      {mainRoutes.map(({ path }, index) => (
        <div className="d-block m-2" key={`mainRoutes-${index}`}>
          <Link to={`${path}`}>{path}</Link>
        </div>
      ))}
    </Fragment>
  );
}

export default Home;
