export const services = [
  '8364',
  '8365',
  '8366',
  '8258',
  '8083',
  '8312',
  '8004',
  '5010',
  '5015',
  '8306',
  '28',
  '8038',
  '5011',
  '8146',
  '15',
  '8252',
  '8251',
  '4190',
  '8006',
  '4100',
  '4081',
  '5608',
  '4088',
  '8109',
  '8110',
  '8111',
  '8023',
  '8097',
  '8270',
  '5801',
  '8101',
  '4084',
  '8204',
  '8205',
  '8000',
  '8327',
  '4140',
  '5900',
  '3990',
  '4010',
  '34',
  '1010',
  '43',
  '8036',
  '8037',
  '8043',
  '8044',
  '4800',
  '4802',
  '4811',
  '4803',
  '4804',
  '4290',
  '4300',
  '4600',
  '4610',
  '5601',
  '5610',
  '8084',
  '8082',
  '8262',
  '4700',
  '4710',
  '5614',
  '8126',
  '3992',
  '8294',
  '8295',
  '3880',
  '8052',
  '8143',
  '8142',
  '3910',
  '8140',
  '8296',
  '3930',
  '3860',
  '3870',
  '3750',
  '3810',
  '3851',
  '3852',
  '3780',
  '3840',
  '3740',
  '3800',
  '8130',
  '3830',
  '8003',
  '5302',
  '5303',
  '25',
  '8164',
  '8162',
  '8163',
  '5103',
  '5108',
  '5110',
  '4330',
  '4340',
  '8170',
  '31',
  '5615',
  '4740',
  '4907',
  '4911',
  '4912',
  '4913',
  '8210',
  '8062',
  '4806',
  '4807',
  '4823',
  '8299',
  '8300',
  '8167',
  '5705',
  '5003',
  '5005',
  '5012',
  '5013',
  '5014',
  '4220',
  '4560',
  '4570',
  '5016',
  '1004',
  '4720',
  '4730',
  '12',
  '4820',
  '4824',
  '4050',
  '4040',
  '5301',
  '4030',
  '3440',
  '8175',
  '8176',
  '8266',
  '8268',
  '8267',
  '8269',
  '8065',
  '8066',
  '8279',
  '8280',
  '8281',
  '3670',
  '3600',
  '8282',
  '8284',
  '8285',
  '8159',
  '8160',
  '5616',
  '8316',
  '8318',
  '8201',
  '8322',
  '8049',
  '8326',
  '8092',
  '8093',
  '8335',
  '8096',
  '8005',
  '8112',
  '8014',
  '8015',
  '4110',
  '4130',
  '5901',
  '5902',
  '8056',
  '4470',
  '8058',
  '5903',
  '8059',
  '8054',
  '5603',
  '5800',
  '8070',
  '8067',
  '8069',
  '8068',
  '8072',
  '4812',
  '4819',
  '4240',
  '4250',
  '4270',
  '4280',
  '4620',
  '8074',
  '8073',
  '5607',
  '5611',
  '5605',
  '5606',
  '5609',
  '5602',
  '5612',
  '8222',
  '8231',
  '24',
  '8090',
  '8091',
  '8088',
  '8089',
  '8260',
  '8261',
  '8259',
  '4080',
  '5613',
  '8131',
  '8223',
  '8224',
  '8053',
  '8304',
  '8303',
  '8311',
  '8310',
  '8309',
  '8308',
  '4085',
  '4086',
  '4082',
  '4083',
  '4090',
  '4091',
  '8132',
  '8133',
  '3760',
  '3820',
  '8148',
  '8147',
  '8150',
  '5304',
  '5305',
  '8287',
  '5100',
  '5105',
  '5101',
  '5106',
  '5102',
  '5107',
  '5104',
  '5109',
  '5111',
  '8115',
  '8116',
  '4390',
  '4400',
  '4370',
  '4380',
  '8186',
  '8187',
  '4350',
  '4360',
  '8206',
  '4540',
  '4550',
  '5204',
  '5205',
  '8179',
  '8180',
  '8177',
  '8178',
  '8244',
  '8021',
  '8022',
  '8017',
  '8018',
  '8024',
  '8025',
  '8172',
  '8173',
  '4750',
  '4901',
  '4905',
  '8203',
  '4909',
  '4818',
  '4919',
  '4917',
  '8297',
  '8298',
  '8218',
  '8219',
  '8220',
  '8168',
  '8158',
  '8157',
  '8153',
  '8154',
  '8042',
  '5704',
  '5703',
  '5707',
  '5004',
  '5000',
  '5202',
  '5203',
  '5008',
  '5009',
  '5017',
  '7',
  '8229',
  '8228',
  '8230',
  '8002',
  '8114',
  '8255',
  '8256',
  '19',
  '8156',
  '4821',
  '8113',
  '5503',
  '8320',
  '4060',
  '8151',
  '8152',
  '4020',
  '3530',
  '3431',
  '3432',
  '3470',
  '3540',
  '3480',
  '3550',
  '1011',
  '3433',
  '3490',
  '3560',
  '3500',
  '3570',
  '3520',
  '39',
  '8271',
  '8275',
  '8272',
  '8273',
  '8274',
  '8276',
  '8277',
  '8278',
  '8125',
  '4480',
  '5400',
  '8196',
  '8050',
  '8051',
  '8213',
  '8214',
  '8221',
  '5604',
  '8217',
  '8047',
  '8127',
  '8128',
  '8301',
  '8302',
  '8317',
  '8080',
  '8081',
  '8145',
  '8144',
  '5006',
  '8198',
  '8200',
  '8323',
  '8324',
  '5802',
  '8032',
  '8033',
  '8030',
  '8031',
  '40',
  '8166',
  '41',
  '8325',
  '8040',
  '8094',
  '8095',
  '4160',
  '4810',
  '8001',
  '4089',
  '4813',
  '8061',
  '4801',
  '4809',
  '4805',
  '4822',
  '4760',
  '8141',
  '32',
  '8165',
  '8286',
  '3580',
  '4180',
  '8046',
  '8041',
  '8171',
  '8226',
  '8215',
  '8174',
  '4808',
  '8035',
  '5306',
  '8071',
  '3850',
  '4915',
  '3720',
  '5500',
  '8253',
  '8254',
  '8333',
  '8334',
  '4310',
  '8348',
  '8347',
  '4320',
  '8329',
  '4825',
  '8155',
  '4914',
  '4902',
  '8034',
  '4904',
  '8027',
  '8020',
  '8019',
  '8026',
  '5706',
  '5617',
  '4814',
  '4816',
  '4815',
  '4817',
  '8136',
  '8138',
  '8137',
  '8139',
  '8169',
  '8055',
  '8057',
  '8184',
  '8188',
  '8185',
  '8189',
  '4918',
  '4910',
  '29',
  '8249',
  '8250',
  '4210',
  '4200',
  '8291',
  '8290',
  '8289',
  '8247',
  '4590',
  '4170',
  '8337',
  '8305',
  '42',
  '8211',
  '8212',
  '4908',
  '8339',
  '4916',
  '8106',
  '8100',
  '8099',
  '8182',
  '8183',
  '8238',
  '8239',
  '1001',
  '1002',
  '1003',
  '8107',
  '8108',
  '8102',
  '8103',
  '20',
  '21',
  '8086',
  '8248',
  '8246',
  '5700',
  '5708',
  '5702',
  '5701',
  '8319',
  '8342',
  '8039',
  '8293',
  '5300',
  '8288',
  '8060',
  '8283',
  '5007',
  '8079',
];

const missingServvices = [
  '2101800',
  '2101801',
  '2102380',
  '2102687',
  '39775',
  '39786',
  '39787',
  '39798',
  '40026',
  '40035',
  '40036',
  '40041',
  '40075',
  '40076',
  '40091',
  '40096',
  '40108',
  '40118',
  '40123',
  '40125',
  '40127',
  '40132',
  '40140',
  '40141',
  '40150',
  '40151',
  '40157',
  '40158',
  '40162',
  '40164',
  '40168',
  '40177',
  '40201',
  '40202',
  '40225',
  '40227',
  '40228',
  '40234',
  '40247',
  '40248',
  '40251',
  '40252',
  '40253',
  '40276',
  '40277',
  '40280',
  '40281',
  '40282',
  '40283',
  '40292',
  '40293',
  '40295',
  '40298',
  '40299',
  '40306',
  '40317',
  '40320',
  '40323',
  '40326',
  '40334',
  '40336',
  '40338',
  '40345',
  '40351',
  '40352',
  '40376',
  '40381',
  '40383',
  '40384',
  '40386',
  '40387',
  '40388',
  '40389',
  '40390',
  '40407',
  '40418',
  '40428',
  '40438',
  '40446',
  '40448',
  '40456',
  '40457',
  '40458',
  '40459',
  '40463',
  '40464',
  '40882',
  '41424',
  '46277',
  '46279',
  '46338',
  '46340',
  '46341',
  '46345',
  '46349',
  '46350',
  '46352',
  '46353',
  '46354',
  '46355',
  '46356',
  '46400',
  '46410',
  '46420',
  '46432',
  '46455',
  '46456',
  '46457',
  '46458',
  '46459',
  '46460',
  '46461',
  '46462',
  '46463',
  '46464',
  '46465',
  '46466',
  '46467',
  '46468',
  '46469',
  '46470',
  '46473',
  '50000',
  '50001',
  '50002',
  '50003',
  '50004',
  '50005',
  '50006',
  '50007',
  '50008',
  '50009',
  '50010',
  '50011',
  '50012',
  '50013',
  '50014',
  '50015',
  '50016',
  '50017',
  '60000',
  '60020',
  '60021',
  '60022',
  '60023',
  '60024',
  '60025',
  '60026',
  '60027',
  '60028',
  '60040',
  '60041',
  '60042',
  '60043',
  '60044',
  '60045',
  '60046',
  '60047',
  '60048',
  '60049',
  '60060',
  '60061',
  '60062',
  '60063',
  '60064',
  '60065',
  '60066',
  '60067',
  '60068',
  '60069',
  '60080',
  '60081',
  '60082',
  '60083',
  '60084',
  '60085',
  '60086',
  '60087',
  '60088',
  '60089',
  '60090',
  '60091',
  '60092',
  '60093',
  '60094',
  '60095',
  '60096',
  '60097',
  '60098',
  '60099',
  '60100',
  '60101',
  '60102',
  '60103',
  '60104',
  '60105',
  '60106',
  '60107',
  '60108',
  '60109',
  '60200',
  '60201',
  '60202',
  '60203',
  '60204',
  '60205',
  '60206',
  '60207',
  '60208',
  '60209',
  '60210',
  '60211',
  '60300',
  '60301',
  '60320',
  '60321',
  '60322',
  '60323',
  '60324',
  '60325',
  '60326',
  '60327',
  '60328',
  '60329',
  '60330',
  '60331',
  '60332',
  '60333',
  '60334',
  '60335',
  '60336',
  '60337',
  '60338',
  '60339',
  '60340',
  '60341',
  '60342',
  '60343',
  '60344',
  '60345',
  '60346',
  '60347',
  '60348',
  '60349',
  '60350',
  '60351',
  '60352',
  '60353',
  '60354',
  '60355',
  '60356',
  '60357',
  '60358',
  '60359',
  '60360',
  '60361',
  '60362',
  '60363',
  '60364',
  '60365',
  '60366',
  '60367',
  '60368',
  '60369',
  '60380',
  '60381',
  '60382',
  '60383',
  '60384',
  '60385',
  '60386',
  '60387',
  '60388',
  '60390',
  '60391',
  '60392',
  '60393',
  '60394',
  '60395',
  '60396',
  '60397',
  '60398',
  '60399',
  '60400',
  '60401',
  '60402',
  '60403',
  '60404',
  '60405',
  '60406',
  '60407',
  '60408',
  '60409',
  '60410',
  '60411',
  '60412',
  '60413',
  '60414',
  '60415',
  '60416',
  '60417',
  '60418',
  '60419',
  '60420',
  '60421',
  '60422',
  '60423',
  '60424',
  '60425',
  '60426',
  '60427',
  '60428',
  '60429',
  '60430',
  '60431',
  '60432',
  '60433',
  '60434',
  '60435',
  '60436',
  '60437',
  '60438',
  '60439',
  '60440',
  '60441',
  '60442',
  '60443',
  '60444',
  '60445',
  '60446',
  '60447',
  '60448',
  '60449',
  '60450',
  '60451',
  '60452',
  '60453',
  '60454',
  '60460',
  '60461',
  '60462',
  '60463',
  '60464',
  '60465',
  '60466',
  '60467',
  '60468',
  '60469',
  '60470',
  '60471',
  '60472',
  '60473',
  '60474',
  '60475',
  '60476',
  '60477',
  '60478',
  '60479',
  '60480',
  '60481',
  '60500',
  '60501',
  '60502',
  '60503',
  '60504',
  '60505',
  '60506',
  '60507',
  '60508',
  '60509',
  '60510',
  '60511',
  '60512',
  '60513',
  '60514',
  '60515',
  '60516',
  '60517',
  '60518',
  '60519',
  '60520',
  '60521',
  '60522',
  '60523',
  '60525',
  '60526',
  '60527',
  '60528',
  '60529',
  '60530',
  '60531',
  '60532',
  '60533',
  '60534',
  '60535',
  '60536',
  '60537',
  '60538',
  '60539',
  '60540',
  '60541',
  '60542',
  '60543',
  '60544',
  '60545',
  '60546',
  '60547',
  '60548',
  '60549',
  '60560',
  '60561',
  '60562',
  '60563',
  '60564',
  '60565',
  '60566',
  '60580',
  '60581',
  '60582',
  '60583',
  '60584',
  '60585',
  '60586',
  '60587',
  '60588',
  '60589',
  '60590',
  '60591',
  '60592',
  '60593',
  '60594',
  '60600',
  '60601',
  '60602',
  '60603',
  '60604',
  '60605',
  '60606',
  '60607',
  '60608',
  '60609',
  '60610',
  '60611',
  '60612',
  '60613',
  '60614',
  '60615',
  '60616',
  '60617',
  '60618',
  '60619',
  '60620',
  '60621',
  '60640',
  '60641',
  '60660',
  '60661',
  '60662',
  '60663',
  '60664',
  '60666',
  '60680',
  '60700',
  '60701',
  '60702',
  '60703',
  '60704',
  '60705',
  '60706',
  '60707',
  '60708',
  '60720',
  '60721',
  '60722',
  '60723',
  '60724',
  '60725',
  '60726',
  '60727',
  '60728',
  '60729',
  '60730',
  '60731',
  '60732',
  '60733',
  '60734',
  '60735',
  '60736',
  '60740',
  '60760',
  '60761',
  '60780',
  '60800',
  '60801',
  '60802',
  '60803',
  '60804',
  '60860',
  '61040',
  '61041',
  '61060',
  '61061',
  '61062',
  '61080',
  '61081',
  '61082',
  '61083',
  '61084',
  '61085',
  '61086',
  '61087',
  '61090',
  '61095',
  '61100',
  '61101',
  '61102',
  '61103',
  '61104',
  '61105',
  '61120',
  '61140',
  '61141',
  '61142',
  '61143',
  '61144',
  '61145',
  '61146',
  '61147',
  '61148',
  '61149',
  '61150',
  '61151',
  '61160',
  '61161',
  '61162',
  '61163',
  '61180',
  '61261',
  '61262',
  '61266',
  '61267',
  '61268',
  '61269',
  '61270',
  '61271',
  '61272',
  '61273',
  '61275',
  '61276',
  '61277',
  '61278',
  '61279',
  '61280',
  '61281',
  '61282',
  '61283',
  '61284',
  '61285',
  '61286',
  '61287',
  '61288',
  '61289',
  '61300',
  '61301',
  '61302',
  '61303',
  '61304',
  '61305',
  '61320',
  '61323',
  '61400',
  '61460',
  '61461',
  '61462',
  '61463',
  '61521',
  '61560',
  '61620',
  '61621',
  '61642',
  '61643',
  '61660',
  '61681',
  '61683',
  '61700',
  '61702',
  '61740',
  '61760',
  '61761',
  '61820',
  '61840',
  '61860',
  '61880',
  '61881',
  '61921',
  '61960',
  '61980',
  '61981',
  '62000',
  '62001',
  '62002',
  '62020',
  '62048',
  '62049',
  '62068',
  '62108',
  '62109',
  '62128',
  '62148',
  '62149',
  '62168',
  '62188',
  '62189',
  '62208',
  '62248',
  '62268',
  '62269',
  '62288',
  '62289',
  '62308',
  '62309',
  '62310',
  '62311',
  '62312',
  '62328',
  '62329',
  '62348',
  '62349',
  '62350',
  '62351',
  '62352',
  '62353',
  '62354',
  '62355',
  '62356',
  '62368',
  '62369',
  '62370',
  '62371',
  '62372',
  '62373',
  '62374',
  '62375',
  '62388',
  '62389',
  '62390',
  '62391',
  '62392',
  '62393',
  '62394',
  '62395',
  '62408',
  '62409',
  '62410',
  '62411',
  '62412',
  '62413',
  '62414',
  '62415',
  '62428',
  '62429',
  '62448',
  '62449',
  '62450',
  '62468',
  '62469',
  '62470',
  '62471',
  '62488',
  '62489',
  '62490',
  '62491',
  '62492',
  '62493',
  '62494',
  '62495',
  '62496',
  '62497',
  '62508',
  '62509',
  '62510',
  '62511',
  '62528',
  '62529',
  '62548',
  '62549',
  '62550',
  '62551',
  '62552',
  '62553',
  '62554',
  '62555',
  '62556',
  '62558',
  '62559',
  '62560',
  '62561',
  '62562',
  '62563',
  '62564',
  '62565',
  '62566',
  '62567',
  '62568',
  '62569',
  '62570',
  '62571',
  '62588',
  '62589',
  '62608',
  '62609',
  '62610',
  '62611',
  '62612',
  '62613',
  '62614',
  '62615',
  '62616',
  '62617',
  '62628',
  '62648',
  '62649',
  '62650',
  '62651',
  '62652',
  '62653',
  '62654',
  '62655',
  '62656',
  '62657',
  '62658',
  '62659',
  '62660',
  '62661',
  '62668',
  '62669',
  '62670',
  '62671',
  '62672',
  '62673',
  '62674',
  '62675',
  '62676',
  '62677',
  '62678',
  '62679',
  '62680',
  '62681',
  '62682',
  '62683',
  '62684',
  '62685',
  '62686',
  '62687',
  '62688',
  '62689',
  '62690',
  '62691',
  '62692',
  '62693',
  '62694',
  '62695',
  '62696',
  '62697',
  '62698',
  '62708',
  '62709',
  '62710',
  '62711',
  '62712',
  '62713',
  '62714',
  '62715',
  '62716',
  '62717',
  '62718',
  '62719',
  '62720',
  '62728',
  '62729',
  '62730',
  '62731',
  '62732',
  '62733',
  '62734',
  '62748',
  '62768',
  '62769',
  '62770',
  '62771',
  '62788',
  '62789',
  '62790',
  '62791',
  '62808',
  '62809',
  '62810',
  '62811',
  '62812',
  '62813',
  '62814',
  '62815',
  '62828',
  '62848',
  '62849',
  '62850',
  '62851',
  '62852',
  '62868',
  '62869',
  '62870',
  '62871',
  '62872',
  '62888',
  '62908',
  '62928',
  '62948',
  '62949',
  '62968',
  '62988',
  '62989',
  '62990',
  '62991',
  '63008',
  '63009',
  '63010',
  '63011',
  '63012',
  '63013',
  '63028',
  '63029',
  '63030',
  '63031',
  '63032',
  '63033',
  '63034',
  '63035',
  '63036',
  '63037',
  '63038',
  '63039',
  '63040',
  '63041',
  '63042',
  '63048',
  '63049',
  '63050',
  '63051',
  '63053',
  '63068',
  '99909',
];
export { default as mainRoutes } from './mainRoutes.json';
