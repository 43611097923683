import React from 'react';
import { Image } from 'react-bootstrap';

import { Logo } from '../../assets';

const HomeAdvisorBadge = () => (
  <div className="homeadvisor-signature mt-3">
    <Image src={Logo} />
    <p style={{ textAlign: 'justify' }}>
      By submitting your info on this form, you are agreeing to be contacted
      regarding your service request by means of telephone, email, or text
      including using pre-recorded or auto dialed phone calls or text messages
      to the phone number you have provided, including your wireless number, if
      provided. Consent to content doesn't require you to purchase service. You
      may be matched to our trusted partners Angi or CraftJack. By using this
      service, you are bound to the Angi{' '}
      <a
        href="https://vault.pactsafe.io/s/a84ad12b-7245-4a12-9fc5-2011a3bf4d62/legal.html#contract-skmav5s0l"
        target="_blank">
        T&C’s
      </a>{' '}
      and{' '}
      <a
        href="https://vault.pactsafe.io/s/a84ad12b-7245-4a12-9fc5-2011a3bf4d62/legal.html#contract-h1zrnbhtx"
        target="_blank">
        Privacy Policy
      </a>{' '}
      and the Craftjack{' '}
      <a
        href="https://legal.craftjack.com/#craftjack-consumer-terms-of-use"
        target="_blank">
        T&Cs
      </a>{' '}
      and{' '}
      <a href="https://legal.craftjack.com/" target="_blank">
        Privacy Policy
      </a>{' '}
      .
    </p>
  </div>
);
export default HomeAdvisorBadge;
