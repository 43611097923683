import React, { Fragment } from "react";
import { HomeAdvisorBadge } from "components";

function Success() {
  return (
    <Fragment>
      <div
        style={{ height: "100vh" }}
        className="w-100 d-flex flex-column align-items-center justify-content-between"
      >
        <div className="px-5 text-center">
          <h2 className="p-3 mt-5 pt-5">Thank You</h2>
          <h5 style={{ color: "rgb(84 84 84)" }}>
            We have received your request. Our team will reach to you soon with
            the shortlisted pros who can help you.
          </h5>
        </div>
        <HomeAdvisorBadge />
      </div>
    </Fragment>
  );
}

export default Success;
