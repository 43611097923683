import React from "react";
import { Switch } from "react-router-dom";
import Routes from "routes.js";
import "./app.scss";

function App() {
  return (
    <Switch>
      <Routes />
    </Switch>
  );
}

export default App;
