import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Form } from 'react-bootstrap';

function MainForm({ data, ...props }) {
  const [selected, setSelected] = useState(false);
  const [error, setError] = useState(false);

  const handleRedirect = () => {
    if (!selected) {
      window.scrollTo(0, 0);
      // window.scrollY(0, 0);
      return setError(true);
    }
    const urlParams = new URLSearchParams(window.location.search);
    const clickId = urlParams.get('clickId');
    const parent = urlParams.get('parent');
    console.log('clickId', urlParams, clickId, window.location.search);
    props.history.push(`${selected}?clickId=${clickId}&parent=${parent}`);
  };

  return (
    <Fragment>
      <div className="p-1 p-md-4">
        <h3 className="font-weight-bold text-muted my-2">{props.title}</h3>
        {error && (
          <span className="text-danger font-weight-bold d-block mb-2">
            <i className="fa fa-arrow-down mr-2" aria-hidden="true" />
            Please choose a category
          </span>
        )}
        <div className="my-2 mt-3">
          {data.map(({ name, path }, index) => (
            <div key={index}>
              <Form.Label
                className={
                  path === selected
                    ? 'show-border text-gray d-flex align-items-center radiobutton scrim-card list-item p-3'
                    : 'text-gray d-flex align-items-center radiobutton scrim-card list-item p-3'
                }>
                <input
                  type={'radio'}
                  name={name}
                  checked={selected === path}
                  onChange={() => {
                    setSelected(path);
                    setError(false);
                  }}
                  className="form-radio"
                />
                <span className="ml-2 text-label">{name}</span>
              </Form.Label>
            </div>
          ))}
        </div>
        <div>
          <button
            className="btn btn-warning btn-lg mt-5 next-btn"
            onClick={() => handleRedirect()}>
            NEXT{' '}
            <i
              className="ml-1 fa fa-play text-dark font-weight-bolder"
              aria-hidden="true"
            />
          </button>
        </div>
      </div>
    </Fragment>
  );
}

export default withRouter(MainForm);
