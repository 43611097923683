import React from "react";
import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

const Checkbox = ({ name, title, fields, isRequired, className }) => {
  const { register, errors } = useFormContext();
  const [state, setState] = React.useState({});
  const [selected, setSelected] = React.useState({});

  const _handleChange = (e, index, text) => {
    const checked = e.target.checked;
    fields[index].isSelected = checked;
    setState({ ...state, [e.target.name]: !checked });
    setSelected({ ...selected, [text]: checked });
  };

  return (
    <Form.Group>
      {errors[name] && (
        <span className="text-danger font-weight-bold d-block mb-2">
          <i className="fa fa-arrow-down mr-2" aria-hidden="true" />
          {errors[name].message}
        </span>
      )}
      <Form.Label>
        {title}
        {isRequired && (
          <span className="text-danger font-weight-bolder ml-1 is-required">
            *
          </span>
        )}
      </Form.Label>
      <Form.Row
        className={`p-0 m-0  ${
          "inline" ? "d-flex align-items-center" : "d-block"
        }`}
      >
        {fields.map(({ _text, _attributes: { id } }, index) => (
          <div className={`${className} mb-2`}>
            <Form.Label
              key={index}
              className={
                selected[_text]
                  ? `text-gray d-flex align-items-center checkbox scrim-card list-item show-border`
                  : `text-gray d-flex align-items-center checkbox scrim-card list-item`
              }
            >
              <input
                type="checkbox"
                className="form-checkbox"
                checked={state[_text]}
                name={name}
                onChange={(event) => {
                  _handleChange(event, index, _text);
                }}
                ref={
                  isRequired &&
                  register({
                    required: "Required",
                  })
                }
              />
              <span className="ml-2 text-label">{_text}</span>
            </Form.Label>
          </div>
        ))}
      </Form.Row>
    </Form.Group>
  );
};

export default Checkbox;
