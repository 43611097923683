import axios from 'axios';
import Zips from 'zipcodes';
import _ from 'lodash';

const normalizeQuestionAnswer = (question) => {
  const data = JSON.parse(JSON.stringify([...question]));
  let finalObj = {};

  for (let {
    _attributes: { id, description, type },

    ...item
  } of data) {
    let temp = {
      _attributes: {
        id: id,
        description: normalize(description),
        type,
      },
    };
    finalObj[`${normalize(description)}`] = '';
    for (let answer of item.answer) {
      if (answer.isSelected) {
        finalObj[`${normalize(description)}`] =
          finalObj[`${normalize(description)}`].length > 0
            ? finalObj[`${normalize(description)}`].concat(`, ${answer._text}`)
            : finalObj[`${normalize(description)}`].concat(`${answer._text}`);
      }
    }

    console.log('final object', finalObj);
  }

  return finalObj;
};

const ping = async (oid, description, zip, clickId) => {
  console.log('data:', { oid, description, zip, clickId });

  let body = new FormData();
  body.append('url', 'https://api.cj-x.com/v1/ping');
  body.append(
    'data',
    JSON.stringify({
      ZipCode: zip,
      ServiceID: parseInt(oid),
      IsTest: false,
      TrafficSource: '1',
    })
  );
  return fetch('https://api.yourrepairexperts.com/generic.php', {
    method: 'POST',
    body,
  });
};

const normalize = (str) => {
  if (!typeof str === 'string') return str;
  return str.replace('<', '&#60;').replace('>', '&#62;').replace('&', '&#38;');
};

const getAnswerById = (interviewList, findId, HomeOwner = false) => {
  let finalAnswer = '';
  for (let {
    _attributes: { id },

    ...item
  } of interviewList) {
    if (id === findId)
      for (let answer of item.answer) {
        if (answer.isSelected) {
          if (HomeOwner) {
            return answer._attributes.id === '10001' ? true : false;
          } else {
            finalAnswer = answer._text;
            return finalAnswer;
          }
        }
      }
  }
  return HomeOwner ? false : finalAnswer;
};

export const sendRequest = async (data) => {
  console.log('final data', data);
  let { oid, description } = data.task.task._attributes;
  let {
    contact: {
      firstName: { _text: FirstName },
      lastName: { _text: LastName },
      TrustedFormUrl,
      contactMethods: { contactMethod },
    },
    location: {
      city: { _text: City },
      addressLine1: { _text: Address },
      zip: { _text: ZipCode },
    },
  } = data.customer;
  let contacts = {
    PhoneNumber: contactMethod[0]._text,
    EmailAddress: contactMethod[1]._text,
  };
  let { _text: zip } = data.customer.location.zip;
  let response = await ping(
    parseInt(oid),
    normalize(description),
    zip,
    data.clickId
  );
  let _response = await response.json();
  console.log('response', _response);

  if (_response.success) {
    let PropertyType = getAnswerById(data.task.task.question, '7735');
    let body = new FormData();
    body.append('url', 'https://api.cj-x.com/v1/post');
    body.append(
      'data',
      JSON.stringify({
        CjLeadKey: _response.cjLeadKey,
        ThirdPartyLeadID: data.clickId,
        ZipCode, //'60606',
        FirstName,
        LastName,
        Address,
        City,
        State: Zips.lookup(ZipCode)?.state,
        PhoneNumber: contacts.PhoneNumber.replace('+1', ''),
        EmailAddress: contacts.EmailAddress,
        TimeFrame: getAnswerById(data.task.task.question, '80000'), //1-2 Weeks',
        Subscribe: false,
        PropertyType: _.isEmpty(PropertyType) ? 'Home/Residence' : PropertyType, //'Residential',
        HomeOwner: getAnswerById(data.task.task.question, '501504', true),
        ProjectUrgency: getAnswerById(data.task.task.question, '90000'), //'Ready to hire',
        TrafficSource: '10',
        TCPAConsentLanguage:
          "By submitting your info on this form, you are agreeing to be contacted regarding your service request by means of telephone, email, or text including using pre-recorded or auto dialed phone calls or text messages to the phone number you have provided, including your wireless number, if provided. Consent to content doesn't require you to purchase service. You may be matched to our trusted partners Angi or CraftJack. By using this service, you are bound to the Angi T&C’s and Privacy Policy and the Craftjack T&Cs and Privacy Policy",
        TCPAConsent: true,
        ServiceID: parseInt(oid),
        IsTest: false,
        TrustedFormUrl,
        QuestionsAndAnswers: normalizeQuestionAnswer(data.task.task.question),
      })
    );
    const resp = await fetch('https://api.yourrepairexperts.com/generic.php', {
      method: 'POST',
      body,
    });
    return await resp.json();
  } else {
    return _response;
  }
};

export const logError = async (body) => {
  const resp = await fetch('https://logger.yourrepairexperts.com/index.php', {
    method: 'POST',
    body,
  });
  return await resp.json();
};
