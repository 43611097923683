import React from "react";
import { Form, FormControl } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

const Select = ({ name, title, fields, isRequired, className }) => {
  const { register, errors } = useFormContext();
  const [selected, setSelected] = React.useState(false);

  const _handleChange = (index) => {
    index = parseInt(index);
    fields.map((_, indx) => {
      if (index !== indx) fields[indx].isSelected = false;
      else fields[indx].isSelected = true;
    });
    setSelected(true);
  };

  return (
    <Form.Group>
      {errors[name] && (
        <span className="text-danger font-weight-bold d-block mb-2">
          <i className="fa fa-arrow-down mr-2" aria-hidden="true" />
          {errors[name].message}
        </span>
      )}
      <Form.Label>
        {title}
        {isRequired && (
          <span className="text-danger font-weight-bolder ml-1 is-required">
            *
          </span>
        )}
      </Form.Label>
      <Form.Row
        className={`p-0 m-0  ${
          "inline" ? "d-flex align-items-center" : "d-block"
        }`}
      >
        <FormControl
          name={name}
          className={
            selected
              ? "form-control scrim-card list-item show-border"
              : "form-control scrim-card list-item"
          }
          onChange={({ target: { value } }) => {
            _handleChange(value);
          }}
          as="select"
          ref={
            isRequired
              ? register({
                  required: "This field is required",
                })
              : null
          }
        >
          <option selected={true} value={""} disabled={true}>
            Select option...
          </option>
          {fields.map(({ _text }, index) => (
            <option value={index}>{_text}</option>
          ))}
        </FormControl>
      </Form.Row>
    </Form.Group>
  );
};

export default Select;
