import React, { useEffect, useState } from 'react';
import { Button, Form, Col } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { sendRequest, logError } from 'services/auth';
import { Input, Alert } from 'components';
import fieldData from './formFields.json';

export default function PersonalDetailsForm({ task }) {
  const history = useHistory();

  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const methods = useForm();
  const onSubmit = async (values) => {
    let customer = fieldData.customer;
    console.log('fieldData.customer', fieldData.customer);
    customer.contact.firstName._text = values.firstName;
    customer.contact.lastName._text = values.lastName;
    customer.contact.TrustedFormUrl = document.getElementsByName(
      'xxTrustedFormCertUrl'
    )[0]?.value;
    customer.contact.contactMethods.contactMethod[0]._text = values.phone;
    customer.contact.contactMethods.contactMethod[1]._text = values.email;
    customer.location.addressLine1._text = values.address;
    customer.location.city._text = values.city;
    customer.location.zip._text = values.zip;

    const urlParams = new URLSearchParams(window.location.search);
    const clickId = urlParams.get('clickId');
    const parent = urlParams.get('parent');

    setLoading(true);
    const formData = new FormData();
    formData.append('created_at', new Date().toISOString());
    formData.append('website_id', 2);
    formData.append('page_url', parent);
    formData.append('city', values.city);
    formData.append('zip_code', values.zip);
    formData.append('click_id', clickId);
    formData.append('service_id', window.location.pathname.split('/')[1]);

    const response = await sendRequest({ task, customer, clickId });
    if (response.success) {
      let node = document.createElement('img');
      node.src = 'https://track.imgtrx2.com/postback?clickid=' + clickId;
      node.id = 'HA-Img';
      node.style = 'display:none !important';
      document.body.appendChild(node);
      formData.append('status', 'success');
      formData.append('message', 'success');
      logError(formData);
      history.push('/success');
    } else {
      formData.append('status', 'failure');
      const csv = `time:${new Date().toISOString()},zip:${values.zip},city:${
        values.city
      },email:${values.zip},clickId:${clickId},errorMessage:${JSON.stringify(
        response.errorMessages
      )}`;

      formData.append('message', response.errorMessages);
      logError(formData);
      setError(response?.errorMessages && response?.errorMessages[0]);
    }
    setLoading(false);
  };

  useEffect(() => {
    var field = 'xxTrustedFormCertUrl';
    var provideReferrer = false;
    var invertFieldSensitivity = false;
    var tf = document.createElement('script');
    tf.type = 'text/javascript';
    tf.async = true;
    tf.src =
      'http' +
      ('https:' == document.location.protocol ? 's' : '') +
      '://api.trustedform.com/trustedform.js?provide_referrer=' +
      escape(provideReferrer) +
      '&field=' +
      escape(field) +
      '&l=' +
      new Date().getTime() +
      Math.random() +
      '&invert_field_sensitivity=' +
      invertFieldSensitivity;
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(tf, s);
  }, []);

  return (
    <FormProvider {...methods}>
      {isError && (
        <Alert
          message={isError}
          open={isError}
          onRequestClose={() => setError(null)}
        />
      )}
      <Form className="mt-1 p-2" onSubmit={methods.handleSubmit(onSubmit)}>
        <Form.Row>
          <Col md={6}>
            <Input
              name={'firstName'}
              label={'First Name'}
              inputRef={{ maxLength: 20 }}
            />
          </Col>
          <Col md={6}>
            <Input
              name={'lastName'}
              label={'Last Name'}
              inputRef={{ maxLength: 20 }}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col md={6}>
            <Input
              name={'email'}
              label={'Email Address'}
              inputRef={{
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Plese enter a valid email address',
                },
              }}
            />
          </Col>
          <Col md={6}>
            <Input
              name={'phone'}
              label={'Phone Number'}
              isPhoneNumber={true}
              inputRef={{
                pattern: {
                  value: /\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/,
                  message: 'Please enter a valid phone number',
                },
              }}
            />
          </Col>
        </Form.Row>
        <Input name={'address'} label={'Address'} type="textarea" />
        <Form.Row>
          <Col md={6}>
            <Input name={'city'} label={'City'} />
          </Col>
          <Col md={6}>
            <Input
              name={'zip'}
              label={'Zip code'}
              inputRef={{
                pattern: {
                  value: /^[0-9]{5}(?:-[0-9]{4})?$/,
                  message: 'Please enter a valid Zip Code',
                },
              }}
            />
          </Col>
        </Form.Row>

        {/* <button
          className="btn btn-secondary btn-lg ml-2 mr-2 next-btn"
          onClick={goBack}>
          <i
            className="fa fa-chevron-left text-dark font-weight-bolder"
            aria-hidden="true"
          />
        </button> */}

        <Button
          variant="warning"
          size="lg"
          type="submit"
          disabled={isLoading}
          className="font-weight-bolder mt-2">
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
      </Form>
    </FormProvider>
  );
}
